import axios from 'axios';
import authStore from "@/store/authStore";

function Report() {
    return axios.create({
        baseURL: config.VUE_APP_REPORTSYSTEM || process.env.VUE_APP_INCIDENTREPORT,
        withCredentials: false,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authStore.getters.getUserManager.getIdToken()
        }
    });
}

function ReportPDF() {
    return axios.create({
        baseURL: config.VUE_APP_REPORTSYSTEM || process.env.VUE_APP_INCIDENTREPORT,
        withCredentials: false,
        headers: {
            Authorization: 'Bearer ' + authStore.getters.getUserManager.getIdToken()
        },
        //responseType: 'arraybuffer'
    });
}

function ReportZip() {
    return axios.create({
        baseURL: config.VUE_APP_REPORTSYSTEM || process.env.VUE_APP_INCIDENTREPORT,
        withCredentials: false,
        headers: {
            Authorization: 'Bearer ' + authStore.getters.getUserManager.getIdToken()
        },
        responseType: 'arraybuffer'
    });
}

export default {

    //Templates
    getTemplates() {
        return new Report().get('/template');
    },
    getTemplateById(id) {
        return new Report().get('/template/' + id);
    },
    putTemplate(id, data) {
        return new Report().put('/template/' + id, data);
    },
    deleteTemplate(id) {
        return new Report().delete('/template/' + id);
    },
    postTemplate(data) {
        return new Report().post('/template', data);
    },
    getNamesOfTemplates() {
        return new Report().get('/template/names');
    },
    getTemplateFields(id) {
        return new Report().get('/template/' + id + '/fields');
    },

    //Reports
    postReport(data, supplementFirstBoolean) {
        return new Report().post('/report', data, {params: {supplementFirstBoolean: supplementFirstBoolean}});
    },
    getReportById(id) {
        return new Report().get('/report/' + id);
    },
    putReport(id, data) {
        return new Report().put('/report/' + id, data);
    },
    deleteReport(id) {
        return new Report().put('/report/' + id);
    },
    getNewItem(id, category) {
        return new Report().get('/report/item/' + id + '/' + category);
    },
    getReportNumber(idArray) {
        return new Report().post('/report/numbers', idArray);
    },
    addCategory(category) {
        return new Report().put('/report/category', category);
    },
    createSupplement(data) {
        return new Report().post('/report/supplement', data);
    },
    searchReports(data) {
        return new Report().post('/report/search', data);
    },
    getReportNotes(id) {
        return new Report().get('/report/' + id + '/notes');
    },
    putReportStatus(reportId, status) {
        return new Report().put('/report/status/' + reportId, {status: status});
    },
    getAllReports() {
        return new Report().get('/report/all');
    },
    getConvertedReports() {
        return new Report().get('/report/convert');
    },
    getReportsByPage(data) {
        return new Report().get('/reports', {
            params: {
                page: data.page,
                count: data.count,
                text: data.text,
                column: data.ascending.column,
                ascending: data.ascending.ascending,
                userType: data.userType
            }
        });
    },
    getApprovedReportsByPage(data) {
        return new Report().get('/report/approved', {
            params: {
                page: data.page,
                count: data.count,
                text: data.text,
                column: data.ascending.column,
                ascending: data.ascending.ascending,
            }
        });
    },
    getReportResultsByPage(data) {
        return new Report().get('/report/results', {
            params: {
                page: data.page,
                count: data.count,
                column: data.ascending.column,
                ascending: data.ascending.ascending,
                restrict: data.restrict || '',
                filterPREA: data.filterPREA || '',
                reportText: data.reportText,
                template: data.template,
                fields: data.fields,
                startDate: data.startDate,
                endDate: data.endDate
            }
        });
    },
    getReportsWithStatus(status) {
        return new Report().get('/report/review', {
            params: {
                status: status
            }
        })
    },
    getReportsByUser(userId) {
        return new Report().get('/report/search/' + userId)
    },

    //File Handling
    getReportFiles(reportId) {
        return new Report().get('/report/files/' + reportId);
    },
    uploadFile(formData) {
        return new Report().post('/report/file', formData);
    },
    deleteFile(data) {
        return new Report().delete('/report/file/' + data.reportId + '/' + data.fileName);
    },
    downloadFile(data) {
        return new Report().get('/report/file/' + data.reportId + '/' + data.fileName, {responseType: 'blob'});
    },
    getPdfHeader(name) {
        return new Report().get('/fileheaders/' + name, {responseType: 'arraybuffer'});
    },
    deleteUploadFolder() {
        return new Report().get('/report/deleteuploadfolder')
    },

    //Cases
    getCases() {
        return new Report().get('/case');
    },
    getCasesByPage(data) {
        return new Report().get('/cases', {
            params: {
                page: data.page,
                count: data.count,
                text: data.text,
                column: data.ascending.column,
                ascending: data.ascending.ascending,
                restrict: data.restrict || '',
                filterPREA: data.filterPREA || ''
            }
        })
    },
    getCaseById(id) {
        return new Report().get('/case/' + id);
    },
    putCase(id, data) {
        return new Report().put('/case/' + id, data);
    },
    deleteCase(id) {
        return new Report().delete('/case/' + id);
    },
    postCase(data) {
        return new Report().post('/case', data);
    },
    hideReport(reportId, caseId, caseObj) {
        return new Report().put('/case/' + caseId + '/report/' + reportId, caseObj)
    },
    assignCase(caseId, userId) {
        return new Report().put('/case/user/add/' + caseId, {userId: userId});
    },
    unassignCase(caseId, userId) {
        return new Report().put('/case/user/remove/' + caseId, {userId: userId});
    },
    setPrimaryUser(caseId, users) {
        return new Report().put('/case/user/primaryuser/' + caseId, {users: users})
    },
    getCasePdf(caseId) {
        return new ReportPDF().get('/case/pdf/' + caseId)
    },
    getCaseDocuments(caseId) {
        return (config.VUE_APP_REPORTSYSTEM || process.env.VUE_APP_INCIDENTREPORT) + '/case/documents/' + caseId
        //return new ReportZip().get('/case/documents/'+caseId, {params: {caseName: caseName}})
    },

    //Users
    getUsers() {
        return new Report().get('/users');
    },

    //Inmates
    getInmates(data) {
        return new Report().get('/inmates', {
            params: {
                mid: data.mid || '',
                firstName: data.firstName || '',
                middleName: data.middleName || '',
                lastName: data.lastName || '',
                dob: data.dob || '',
                ssn: data.ssn || ''
            }
        })
    },
    getInmateByMID(MID) {
        return new Report().get('/inmate', {
            params: {
                MID: MID
            }
        })
    },

    //Officers
    getOfficers(data) {
        return new Report().get('/officers', {
            params: {
                firstName: data.firstName || '',
                lastName: data.lastName || '',
                starNumber: data.starNumber || ''
            }
        })
    },
    getOfficerByStarNumber(starNumber) {
        return new Report().get('/officer', {
            params: {
                starNumber: starNumber
            }
        })
    },
}